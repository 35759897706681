 
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent
} from '@/components/ui/dialog' 
import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createUnsettledSecuritiesInContractControl } from '@/api/business/contractcontrol'
import { isAxiosError } from '@/api/business'
import { useEffect, useMemo, useState } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button" 
import { FilePen, FilterX, RefreshCcw  } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography' 
import { useTable } from '@/hooks/useTable'
import { createColumnHelper } from '@tanstack/react-table'
import { currencyFormat } from '@/utils/stringFormatter'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date' 
import { useUnsettledSecuritiesContractControlQuery } from '@/queries/useUnsettledSecuritiesContractControlQuery'
import { UnsettledSecuritiesContractControl } from '@/types/UnsettledSecuritiesContractControl'
import { UnsettledSecuritiesContractControlKeys } from '@/queries/useUnsettledSecuritiesContractControlQuery'
import { UnsettledSecuritiesInContractControlKeys } from '@/queries/useUnsettledSecuritiesInContractControlQuery'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'

export type UnsettledSecuritiesContractControldTable = TableData<UnsettledSecuritiesContractControl>

const columnHelper = createColumnHelper<UnsettledSecuritiesContractControl>()

interface DialogProps {
    isOpen: boolean
    onClose: () => void 
    ids: string | number
}

const UnsettledSecuritiesContractControlDialog = ({ isOpen, onClose, ids}: DialogProps) => {
    const {
        table,  
        setTable,
        selectedRows,
        setSelectedRows,
        
    } = useTable<UnsettledSecuritiesContractControl>('')  

    const queryClient = useQueryClient()
    const { toast } = useToast() 
    const [unsettledSecuritiesSelected, setUnsettledSecuritiesSelected] = useState<string[]>([])
    const { data, isFetching, isPending, refetch } = useUnsettledSecuritiesContractControlQuery(ids)
    
   useEffect(() => {
        refetch()
   }, [ids])

    useEffect(() => {
        const ids = table ? table.getFilteredRowModel()
              .flatRows.filter(
                  (row) => selectedRows[row.id]
              )
              .map((row) => row.original.SK_TITULOS_EM_ACORDO) : []

        setUnsettledSecuritiesSelected(ids)
    }, [selectedRows])
    
    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DD_NUMERO_DOCUMENTO', {
                id: 'DD_NUMERO_DOCUMENTO',
                meta: {
                    row: {isGrouped: true}
                },
                header: 'Número Documento',
                size: 200 
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Empresa',
                size: 100,
            }), 
            columnHelper.accessor('DD_PARCELA', {
                id: 'DD_PARCELA',
                header: 'Parcela',
                size: 90,
            }), 
            columnHelper.accessor('DD_PORTADOR', {
                id: 'DD_PORTADOR',
                header: 'Portador',
                size: 100,
            }),  
            columnHelper.accessor(({ VL_TITULO }) =>
                VL_TITULO
                    ? currencyFormat(Number(VL_TITULO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TITULO',
                header: 'Valor',
                size: 140,
            }),  
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo',
                size: 75,
            }), 
            columnHelper.accessor('DD_NATUREZA_FINANCEIRA', {
                id: 'DD_NATUREZA_FINANCEIRA',
                header: 'Natureza Financeira',
                size: 240,
            }), 
            columnHelper.accessor(({ SK_EMISSAO }) =>
                SK_EMISSAO
                    ? format(getDateFromId(Number(SK_EMISSAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_EMISSAO',
                header: 'Data Emissão',
                size: 130,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO }) =>
                SK_VENCIMENTO
                    ? format(getDateFromId(Number(SK_VENCIMENTO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO',
                header: 'Data Vencimento',
                size: 150,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO_REAL }) =>
                SK_VENCIMENTO_REAL
                    ? format(getDateFromId(Number(SK_VENCIMENTO_REAL)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO_REAL',
                header: 'Data Venc. Real',
                size: 140,
            }),  
            columnHelper.accessor('DD_POSICAO', {
                id: 'DD_POSICAO',
                header: 'Posição',
                size: 140,
            }), 
            
                
        ],
        []
    )

    const { mutate } = useMutation({
        mutationFn: createUnsettledSecuritiesInContractControl,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: UnsettledSecuritiesInContractControlKeys.all })
            queryClient.invalidateQueries({ queryKey: UnsettledSecuritiesContractControlKeys.all })
            setSelectedRows({}) 
            toast({
                title: 'Contrato criado com sucesso',
            })
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível criar um contrato. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar contrato',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })
          
    const onSubmit = () => { 
        mutate({
             SK_CONTROLE_CONTRATO: ids,
             SK_TITULOS_EM_ACORDO: unsettledSecuritiesSelected
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[80%] h-3/4'>  
                <Table<UnsettledSecuritiesContractControldTable>
                        data={memoData}
                        columns={memoColumns}
                        isLoading={isPending}
                        isFetching={isFetching} 
                        expandAllRows={true}   
                        enableRowSelection
                        tableState={{
                            rowSelection: selectedRows,
                        }}
                        onRowSelectionChange={setSelectedRows}
                        tableHeader={(
                            <TypographyH4>Títulos para Vincular no Contrato #{ids}</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                {unsettledSecuritiesSelected.length > 0 && (
                                    <Button
                                        className="flex items-center  gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                        variant={'ghost'}
                                        onClick={() => table && onSubmit()}
                                    >
                                        <FilePen size={14} />
                                        Vincular Títulos no Contrato
                                    </Button>  
                                )}
                                <Button
                                    className="flex items-center  gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button> 
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                    <RefreshCcw size={18} />
                                </ButtonWithTooltip> 
                                
                            </div>
                        )}     
                        getTableInstance={(table) => { 
                            setTable(table) 
                        }} 
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    />
            </DialogContent>
        </Dialog>
    )
}
    
export default UnsettledSecuritiesContractControlDialog
