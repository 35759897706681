import instance from '@/api/business' 
import { UnsettledSecuritiesInAgreement } from './type'

const BASE_URL = '/unsetlledsecuritiesinagreement/crweb/unsetlledsecuritiesinagreement'

export const fetchUnsettledSecuritiesInAgreement = async () => {
    const response = await instance.post<UnsettledSecuritiesInAgreement[]>(`${BASE_URL}`)

    return response.data
}
 