import { fetchUnsettledSecuritiesContractControl } from '@/api/business/contractcontrol'
import { useQuery } from '@tanstack/react-query'

export const UnsettledSecuritiesContractControlKeys = {
    all: ['unsettledsecuritiescontractcontrol'] as const, 
    details: () => [...UnsettledSecuritiesContractControlKeys.all, 'detail'] as const,
    detail: (params: (number | string)) => [...UnsettledSecuritiesContractControlKeys.details(), params] as const,
}

export const useUnsettledSecuritiesContractControlQuery = (SK_CONTROLE_CONTRATO: (number | string)) => {
    return useQuery({
        queryFn: () => fetchUnsettledSecuritiesContractControl(SK_CONTROLE_CONTRATO),
        queryKey: UnsettledSecuritiesContractControlKeys.detail(SK_CONTROLE_CONTRATO),
    })
}
