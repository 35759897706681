import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'

import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { ContractControlTable } from '../..'
import { updateContractControl } from '@/api/business/contractcontrol'
import { ContractControlKeys } from '@/queries/useContractControlQuery'
import Button from '@/components/Button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "@/components/ui/popover" 
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"
import { Input } from '@/components/ui/input'
import { useEffect } from 'react'
import { Textarea } from '@/components/ui/textarea'
import { getDateFromId, getIdFromDate } from '@/utils/date'

interface EditDialogProps {
    row: Row<ContractControlTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({
        NM_CONTRATO: z.string().max(200, {message: 'Maximo de 200 caracteres'}).min(1, {
            message: 'O campo Nome do contrato é obrigatório',
        }),
        SK_TEMPO_VENCIMENTO: z.date({
            required_error: "Data de Vencimento é Obrigatório",
        }),
        SK_TEMPO_INCIO_NOTIFICACAO: z.date({
            required_error: "Data de Inicio de Notificação de Vencimento é Obrigatório",
        }),
        DS_EMAILS_NOTIFICACAO: z.string().max(1000, {message: 'Maximo de 200 caracteres'}),
        DS_CONTRATO: z.string() 
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: { 
            NM_CONTRATO: row.original.NM_CONTRATO || "", 
            SK_TEMPO_VENCIMENTO:  row.original.SK_TEMPO_VENCIMENTO ? getDateFromId(row.original.SK_TEMPO_VENCIMENTO) : new Date(), 
            SK_TEMPO_INCIO_NOTIFICACAO:  row.original.SK_TEMPO_INCIO_NOTIFICACAO ? getDateFromId(row.original.SK_TEMPO_INCIO_NOTIFICACAO) : new Date(), 
            DS_EMAILS_NOTIFICACAO: row.original.DS_EMAILS_NOTIFICACAO || "", 
            DS_CONTRATO: row.original.DS_CONTRATO || "", 
        },
    })

    useEffect(() => {
        form.reset({
            NM_CONTRATO: row.original.NM_CONTRATO || "", 
            SK_TEMPO_VENCIMENTO:  row.original.SK_TEMPO_VENCIMENTO ? getDateFromId(row.original.SK_TEMPO_VENCIMENTO) : new Date(), 
            SK_TEMPO_INCIO_NOTIFICACAO:  row.original.SK_TEMPO_INCIO_NOTIFICACAO ? getDateFromId(row.original.SK_TEMPO_INCIO_NOTIFICACAO) : new Date(), 
            DS_EMAILS_NOTIFICACAO: row.original.DS_EMAILS_NOTIFICACAO || "", 
            DS_CONTRATO: row.original.DS_CONTRATO || "", 
        })
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateContractControl,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ContractControlKeys.lists() })
            toast({
                title: 'Contrato atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar o Contrato. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar Contrato',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => { 
        mutate({
            SK_CONTROLE_CONTRATO: row.original.SK_CONTROLE_CONTRATO,
            DS_CONTRATO: data.DS_CONTRATO,
            SK_TEMPO_VENCIMENTO: data.SK_TEMPO_VENCIMENTO ? Number(getIdFromDate(data.SK_TEMPO_VENCIMENTO).join('')) : null,
            SK_TEMPO_INCIO_NOTIFICACAO: data.SK_TEMPO_INCIO_NOTIFICACAO ? Number(getIdFromDate(data.SK_TEMPO_INCIO_NOTIFICACAO).join('')) : null,
            DS_EMAILS_NOTIFICACAO: data.DS_EMAILS_NOTIFICACAO,
            NM_CONTRATO: data.NM_CONTRATO
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto'>
                <DialogHeader className='px-2'>
                    <DialogTitle>Editar Contrato</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2 px-2 overflow-hidden overflow-y-auto"
                    >
                        <div className='grid gap-4'>
                            <FormField
                                name="NM_CONTRATO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome do Contrato</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div> 
                        <div className='flex gap-4'>
                            <FormField
                                name="SK_TEMPO_VENCIMENTO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className="flex flex-col">
                                        <FormLabel>Data do Vencimento do Contrato</FormLabel>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                            <FormControl>
                                                <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "w-[240px] pl-3 text-left font-normal",
                                                    !field.value && "text-muted-foreground"
                                                )}
                                                >
                                                {field.value ? (
                                                    format(field.value, "PPP")
                                                ) : (
                                                    <span>Selecionar Data</span>
                                                )}
                                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                </Button>
                                            </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0" align="start">
                                            <Calendar
                                                mode="single"
                                                selected={field.value}
                                                onSelect={field.onChange} 
                                                initialFocus
                                            />
                                            </PopoverContent>
                                        </Popover> 
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="SK_TEMPO_INCIO_NOTIFICACAO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem className="flex flex-col">
                                    <FormLabel>Data para início da notificação de Vencimento</FormLabel>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                        <FormControl>
                                            <Button
                                            variant={"outline"}
                                            className={cn(
                                                "w-[240px] pl-3 text-left font-normal",
                                                !field.value && "text-muted-foreground"
                                            )}
                                            >
                                            {field.value ? (
                                                format(field.value, "PPP")
                                            ) : (
                                                <span>Selecionar Data</span>
                                            )}
                                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                            </Button>
                                        </FormControl>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                            mode="single"
                                            selected={field.value}
                                            onSelect={field.onChange} 
                                            initialFocus
                                        />
                                        </PopoverContent>
                                    </Popover> 
                                    <FormMessage />
                                </FormItem>
                                )}
                            /> 
                        </div> 
                        <div className='grid  gap-4'> 
                            <FormField
                                name="DS_EMAILS_NOTIFICACAO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>E-Mails para Notificação de Vencimento</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid  gap-4'> 
                            <FormField
                                name="DS_CONTRATO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Descrição do Contrato</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" isLoading={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter> 
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
