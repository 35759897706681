import { TypographyH4, TypographyMuted, TypographyXS   } from "@/components/ui/typography" 
import { useTotalPositionQuery } from "@/queries/useTotalPositionQuery"
import { useEffect, useState } from "react"
import { BarChart, RefreshCcw, Text } from 'lucide-react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import Position1Dashboard from "./components/Position1Dashboard"
import Position2Dashboard from "./components/Position2Dashboard"
import Position3Dashboard from "./components/Position3Dashboard"
import PositionTotalDashboard from "./components/PositionTotalDashboard"
import Button from "@/components/Button";
import { useProductTotalPositionQuery } from "@/queries/useUnsettledSecuritiesProductTotalPositionQuery";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'

import VirtualizedCommand from '@/components/VirtualizedCommand'
import ButtonWithTooltip from "@/components/ButtonWithTooltip";

const Dashboard = () => {
 
    const [selectDistributor,setSelectDistributor] = useState<string>()
    const [distributor, setDistributor] = useState<string[]>([])

    const [selectUFDistributor,setSelectUFDistributor] = useState<string[]>([])
    const [UFDistributor, setUFDistributor] = useState<string[]>([])

    const [isChartActive, setIsChartActive] = useState<boolean>(false)

    const { data, isFetching, refetch } = useTotalPositionQuery() 
    const { data: dataProduct, refetch: refetchProduct} = useProductTotalPositionQuery()
    
    useEffect(() => {
        if(data){
            const UniqueValues = data ? Array.from(new Set(data.map(item => item.NM_PROPRIETARIO))) : [] 
            setSelectDistributor(UniqueValues.sort()[0]) 
            setDistributor(UniqueValues.sort()) 
        }
    },[data])

    useEffect(() => {
        
        if(data){
            const UniqueValues = distributor ? Array.from(new Set(data.filter( item => item.NM_PROPRIETARIO == selectDistributor).map(item => item.DD_ESTADO))) : [] 
            
            setSelectUFDistributor([UniqueValues.sort()[0]])  
            setUFDistributor(UniqueValues.sort()) 
        }
    },[selectDistributor])
 

    return (
        <div className="flex flex-col h-full overflow-auto p-4">
            <TypographyH4>Dashboard</TypographyH4>
            <div className="flex gap-4 mb-2">
                <div className="mx-2 flex">
                    <TypographyMuted className="content-center mr-2">Distribuidores:</TypographyMuted>
                    <div className="w-[200px]">
                        <Select onValueChange={(value) => setSelectDistributor(value)} value={selectDistributor}>
                            <SelectTrigger> 
                                <SelectValue />
                            </SelectTrigger>  
                            <SelectContent className="w-[220px]">
                            {distributor?.map((option, index) => (  
                                <SelectItem key={index} value={option}>{option}</SelectItem> 
                            ))}
                            </SelectContent>
                        </Select> 
                    </div>
                </div>
                <div className="mx-2 flex">
                    <TypographyMuted className="content-center mr-2">UF:</TypographyMuted>
                    <div className="w-[140px]">
                         
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="justify-start w-full gap-2 text-xs"
                                >
                                    {selectUFDistributor?.length == 0 ? (
                                        <TypographyXS className="flex-1 text-start">
                                            Selecione uma UF
                                        </TypographyXS>
                                    ) : (
                                        <>
                                            <TypographyXS className="p-1 overflow-hidden text-xs font-normal text-accent-foreground text-ellipsis">
                                                {selectUFDistributor.join(", ")}
                                            </TypographyXS>
                                        </>
                                    )}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="z-50 p-0" align="start">
                                <VirtualizedCommand
                                    options={UFDistributor.filter(Boolean).map(item => { return { value: item, label: item } })}
                                    placeholder="UF"
                                    onSelectionAllChange={() => {
                                        setSelectUFDistributor(UFDistributor)
                                    }}
                                    onClearFilters={() => {setSelectUFDistributor([])}}
                                    selectedValues={
                                        selectUFDistributor?.reduce((acc, curr) => {
                                            return {
                                                ...acc,
                                                [curr]: true,
                                            }
                                        }, {} as Record<string, boolean>) ?? {}
                                    }
                                    onSelectionChange={(data) => {
                                        console.log(data)
                                        setSelectUFDistributor((prevItems) => {
                                            // Verifica se o item já existe no array
                                            if (prevItems?.includes(data)) {
                                              // Remove o item do array
                                              return prevItems?.filter((i) => i !== data);
                                            } else {
                                              // Adiciona o item ao array
                                              return [...prevItems, data];
                                            }
                                          }) 
                                    }}
                                />
                            </PopoverContent>
                        </Popover>
                        
                    </div>
                </div>
                <div className="mx-2 flex gap-2">
                    <TypographyMuted className="content-center">Visão:</TypographyMuted>
                    <Button variant={`${isChartActive == false ? 'default' : 'outline'}`} size={"icon"} onClick={() => setIsChartActive(false)}>
                        <Text/> 
                    </Button>
                    <Button variant={`${isChartActive? 'default' : 'outline'}`} size={"icon"} onClick={() => setIsChartActive(true)}>
                        <BarChart/> 
                    </Button>
                </div>
                <ButtonWithTooltip
                    tooltipContent={<p className="text-white">Atualizar</p>}
                    onClick={() => { refetch(), refetchProduct() } }
                    variant="ghost" 
                >
                <RefreshCcw size={18} className={isFetching ? 'animate-spin ' : ''}/>
                </ButtonWithTooltip>
            </div>  
                
            {data && selectDistributor && selectUFDistributor && dataProduct && (
                <div className="flex h-full gap-3 flex-col">
                    <div className="flex h-full gap-3">
                        <Position1Dashboard data={data} dataProduct={dataProduct} distributor={selectDistributor} uf={selectUFDistributor} isChartActive={isChartActive} />
                        <Position2Dashboard data={data} dataProduct={dataProduct} distributor={selectDistributor} uf={selectUFDistributor} isChartActive={isChartActive} />
                        <Position3Dashboard data={data} dataProduct={dataProduct} distributor={selectDistributor} uf={selectUFDistributor} isChartActive={isChartActive}/>
                    </div>

                    <div className=" flex h-full rounded-xl bg-muted gap-3">
                        <PositionTotalDashboard data={data} dataProduct={dataProduct} distributor={selectDistributor} uf={selectUFDistributor}  isChartActive={isChartActive}/>
                    </div>
                </div>
            )}  
        </div>
    )
}

export default Dashboard
