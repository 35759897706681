import { fetchUnsettledSecuritiesInAgreement } from '@/api/business/unsettledSecuritiesInAgreement'
import { useQuery } from '@tanstack/react-query'

export const UnsettledSecuritiesInAgreement = {
    all: ['unsettledsecuritiesinagreement'] as const,
    lists: () => [...UnsettledSecuritiesInAgreement.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...UnsettledSecuritiesInAgreement.lists(), page, perPage, filters] as const,
    details: () => [...UnsettledSecuritiesInAgreement.all, 'detail'] as const,
    detail: (params: string) => [...UnsettledSecuritiesInAgreement.details(), params] as const,
}

export const useUnsettledSecuritiesInAgreementQuery = () => {
    return useQuery({
        queryFn: fetchUnsettledSecuritiesInAgreement,
        queryKey: UnsettledSecuritiesInAgreement.lists(),
    })
}
