import { UnsettledSecuritiesTotalPosition, ProductTotalPosition } from "@/types/UnsettledSecurities"
import { useEffect, useState } from "react"
import PositionTotalChart from "./componentes/chart"
import PositionTotalText from "./componentes/text"

interface PositionProps {
    data: UnsettledSecuritiesTotalPosition[] 
    dataProduct: ProductTotalPosition[]
    uf: string[]
    distributor: string 
    isChartActive: boolean
}

type BarProps = {
    "Em Carteira": number
    "Em Acordo": number
    Tipo: string
}

type informationProps = {
    overdueAmount: number
    amountDueToday: number
    amountToMature: number
    overdueAmountAgreement: number
    amountDueTodayAgreement: number
    amountToMatureAgreement: number
    overdueAmountpercentageOfRepresentation: number
    amountDueTodaypercentageOfRepresentation: number
    amountToMaturepercentageOfRepresentation: number
    overdueAmountAgreementpercentageOfRepresentation: number
    amountDueTodayAgreementpercentageOfRepresentation: number
    amountToMatureAgreementpercentageOfRepresentation: number
    amountProductTypePA: number
    amountProductTypeAI: number
    amountProductTypePAPercentageOfRepresentation: number
    amountProductTypeAIPercentageOfRepresentation: number 
    averageDaysPA: number
    averageDaysAI: number
}

 const PositionTotalDashboard = ({data, dataProduct, uf, distributor, isChartActive} : PositionProps) => { 
    const [bar, setBarValues] = useState<BarProps[]>()
    const [information, setinformation] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0,
        averageDaysAI: 0
     }) 
     
     useEffect(() => { 
         const filteredDataDistributorInUF = data.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
         const filteredDataDistributor = data.filter(item => item.NM_PROPRIETARIO == distributor)
         const filteredDataProductDistributorInUF = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
         const filteredDataProductDistributor = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor)
        
 
         const sumOverdueAmount = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_P2 || 0) + (currentValue.VL_VENCIDO_P1 || 0) + (currentValue.VL_VENCIDO_P3|| 0)) ;
         }, 0);
 
         const sumAmountDueToday = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_HOJE_P2 || 0) + (currentValue.VL_VENCIDO_HOJE_P1 || 0) + (currentValue.VL_VENCIDO_HOJE_P3|| 0)) ;
         }, 0);
         
         const sumAmountToMature = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_A_VENCER_P2 || 0) + (currentValue.VL_A_VENCER_P1 || 0) + (currentValue.VL_A_VENCER_P3|| 0)) ;
         }, 0);
 
         const sumOverdueAmountAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_ACORDO_P2 || 0) + (currentValue.VL_VENCIDO_ACORDO_P1 || 0) + (currentValue.VL_VENCIDO_ACORDO_P3|| 0)) ;
         }, 0);
 
         const sumAmountDueTodayAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_ACORDO_HOJE_P2 || 0) + (currentValue.VL_VENCIDO_ACORDO_HOJE_P1 || 0) + (currentValue.VL_VENCIDO_ACORDO_HOJE_P3|| 0)) ;
         }, 0);
         
         const sumAmountToMatureAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_A_VENCER_ACORDO_P2 || 0) + (currentValue.VL_A_VENCER_ACORDO_P1 || 0) + (currentValue.VL_A_VENCER_ACORDO_P3|| 0)) ;
         }, 0);
 
         const sumOverdueAmountTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_P2 || 0) + (currentValue.VL_VENCIDO_P1 || 0) + (currentValue.VL_VENCIDO_P3|| 0)) ;
         }, 0);
 
         const sumAmountDueTodayTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_HOJE_P2 || 0) + (currentValue.VL_VENCIDO_HOJE_P1 || 0) + (currentValue.VL_VENCIDO_HOJE_P3|| 0)) ;
         }, 0);
         
         const sumAmountToMatureTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_A_VENCER_P2 || 0) + (currentValue.VL_A_VENCER_P1 || 0) + (currentValue.VL_A_VENCER_P3|| 0)) ;
         }, 0);
 
         const sumOverdueAmountAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_ACORDO_P2 || 0) + (currentValue.VL_VENCIDO_ACORDO_P1 || 0) + (currentValue.VL_VENCIDO_ACORDO_P3|| 0)) ;
         }, 0);
 
         const sumAmountDueTodayAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_VENCIDO_ACORDO_HOJE_P2 || 0) + (currentValue.VL_VENCIDO_ACORDO_HOJE_P1 || 0) + (currentValue.VL_VENCIDO_ACORDO_HOJE_P3|| 0)) ;
         }, 0);
         
         const sumAmountToMatureAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
             return accumulator + Number((currentValue.VL_A_VENCER_ACORDO_P2 || 0) + (currentValue.VL_A_VENCER_ACORDO_P1 || 0) + (currentValue.VL_A_VENCER_ACORDO_P3|| 0)) ;
         }, 0);

         const sumAmountProductTypePA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
         .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.VL_VENCIDO_E_A_VENCER_P1 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P2 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P3|| 0));
        }, 0);

        const sumAmountProductTypeAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
        .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.VL_VENCIDO_E_A_VENCER_P1 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P2 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P3|| 0));
        }, 0); 

        const sumAmountProductTypePATotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
        .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.VL_VENCIDO_E_A_VENCER_P1 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P2 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P3|| 0));
        }, 0);

        const sumAmountProductTypeAITotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
        .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.VL_VENCIDO_E_A_VENCER_P1 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P2 || 0) + (currentValue.VL_TOTAL_COM_JUROS_P3|| 0));
        }, 0);

        const sumAverageDaysPA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
        .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.DD_DIAS_UTEIS_P1 || 0) + (currentValue.DD_DIAS_UTEIS_P2 || 0) + (currentValue.DD_DIAS_UTEIS_P3|| 0));
        }, 0);

        const sumAverageDaysAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
        .reduce((accumulator, currentValue) => {
            return accumulator + Number((currentValue.DD_DIAS_UTEIS_P1 || 0) + (currentValue.DD_DIAS_UTEIS_P2 || 0) + (currentValue.DD_DIAS_UTEIS_P3|| 0));
        }, 0); 
 
        
        setinformation({
            overdueAmount : sumOverdueAmount,
            amountDueToday: sumAmountDueToday,
            amountToMature: sumAmountToMature,
            overdueAmountAgreement: sumOverdueAmountAgreement,
            amountDueTodayAgreement: sumAmountDueTodayAgreement,
            amountToMatureAgreement : sumAmountToMatureAgreement,
            overdueAmountpercentageOfRepresentation: sumOverdueAmountTotal > 0 ? (sumOverdueAmount / sumOverdueAmountTotal) * 100 : 0,
            amountDueTodaypercentageOfRepresentation: sumAmountDueTodayTotal > 0 ? (sumAmountDueToday / sumAmountDueTodayTotal) * 100 : 0,
            amountToMaturepercentageOfRepresentation: sumAmountToMatureTotal > 0 ? (sumAmountToMature / sumAmountToMatureTotal) * 100 : 0,
            overdueAmountAgreementpercentageOfRepresentation: sumOverdueAmountAgreementTotal > 0 ? (sumOverdueAmountAgreement / sumOverdueAmountAgreementTotal) * 100 : 0,
            amountDueTodayAgreementpercentageOfRepresentation: sumAmountDueTodayAgreementTotal > 0 ? (sumAmountDueTodayAgreement / sumAmountDueTodayAgreementTotal) * 100 : 0,
            amountToMatureAgreementpercentageOfRepresentation: sumAmountToMatureAgreementTotal > 0 ? (sumAmountToMatureAgreement / sumAmountToMatureAgreementTotal) * 100 : 0,
            amountProductTypePA: sumAmountProductTypePA,
            amountProductTypeAI: sumAmountProductTypeAI,
            amountProductTypePAPercentageOfRepresentation: sumAmountProductTypePATotal > 0 ? (sumAmountProductTypePA / sumAmountProductTypePATotal) * 100 : 0,
            amountProductTypeAIPercentageOfRepresentation: sumAmountProductTypeAITotal > 0 ? (sumAmountProductTypeAI / sumAmountProductTypeAITotal) * 100 : 0, 
            averageDaysPA: ((sumAverageDaysPA || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA").length || 0) || 0),
            averageDaysAI: ((sumAverageDaysAI || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI").length || 0) || 0)
         })
  
        setBarValues([
            {
                Tipo: 'Valor Vencido',
                "Em Carteira": sumOverdueAmount,
                "Em Acordo":  sumOverdueAmountAgreement
            }, 
            {
                Tipo: 'Valor Vencido Hoje',
                "Em Carteira": sumAmountDueToday,
                "Em Acordo":  sumAmountDueTodayAgreement
            }, 
            {
                Tipo: 'Em Aberto',
                "Em Carteira": sumAmountToMature,
                "Em Acordo":  sumAmountToMatureAgreement
            }, 
        ])
     },[data, uf, distributor])

    return (
        <>
        {information && isChartActive == false && (
            <PositionTotalText information={information} distributor={distributor} uf={uf} />
        )}
        {bar && isChartActive && (
            <PositionTotalChart bar={bar} />
        )}
    </> 
    )
}

export default PositionTotalDashboard
