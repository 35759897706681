import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox' 
import { Row } from '@tanstack/react-table'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/components/ui/use-toast'
import { isAxiosError } from '@/api/business'
import { CustomerTable } from '../'
import { updateCustomer } from '@/api/business/customer'
import { CustomerKeys } from '@/queries/useCustomerQuery'
import Button from '@/components/Button'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form' 
import { useEffect } from 'react' 

interface EditDialogProps {
    row: Row<CustomerTable>
    isOpen: boolean
    onClose: () => void
}

const EditDialog = ({ row, isOpen, onClose }: EditDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const schema = z.object({  
        DD_CLASSIFICA_NAO_ANTECIPAVEIS: z.number(), 
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            DD_CLASSIFICA_NAO_ANTECIPAVEIS: row.original.DD_CLASSIFICA_NAO_ANTECIPAVEIS || 0
        },
    })

    useEffect(() => {
        form.reset({
            DD_CLASSIFICA_NAO_ANTECIPAVEIS: row.original.DD_CLASSIFICA_NAO_ANTECIPAVEIS || 0 
        })
    }, [row.original])

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: updateCustomer,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: CustomerKeys.lists() })
            toast({
                title: 'Cliente atualizado com sucesso',
            })
            onClose()
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível editar a Cliente. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao editar Cliente',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            SK_CLIENTE: row.original.SK_CLIENTE,
           ...data
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[350px] overflow-auto '>
                <DialogHeader className='px-2'>
                    <DialogTitle>Editar Cliente</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2 px-2 overflow-hidden overflow-y-auto"
                    >
                        <FormField
                            control={form.control}
                            name="DD_CLASSIFICA_NAO_ANTECIPAVEIS"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 py-2">
                                    <FormControl>
                                        <Checkbox
                                             checked={Boolean(field.value)}
                                             onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                                        />
                                    </FormControl>
                                    <div className="space-y-1 leading-none">
                                        <FormLabel>
                                            Não Antecipavel?
                                        </FormLabel> 
                                        <FormMessage />
                                    </div>
                                </FormItem>
                            )}
                        />
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" isLoading={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter> 
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditDialog
