import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useContractControlQuery } from '@/queries/useContractControlQuery'
import { ContractControl } from '@/types/ContractControl'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import DadosMestreHeader from '../components/DadosMestreHeader'
import TableRowActions from '@/components/Table/components/TableRowActions'
import DeleteDialog from './components/DeleteDialog'
import EditDialog from './components/EditDialog'
import AddDialog from './components/AddDialog'
import DadosMestreHeaderActions from '../components/DadosMestreHeaderActions'  
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import { BookMarked, Newspaper } from 'lucide-react'
import useDisclosure from '@/hooks/useDisclosure'
import UnsettledSecuritiesContractControlDialog from './components/UnsettledSecuritiesContractControlDialog'
import UnsettledSecuritiesInContractControlDialog from './components/UnsettledSecuritiesInContractControlDialog'

export type ContractControlTable = TableData<ContractControl>

const columnHelper = createColumnHelper<ContractControl>()

const MasterDataContractControl = () => {
    const {
        table, 
        selectedRow,
        selectedRows,
        isAddDialogOpen,
        isDeleteDialogOpen,
        isEditDialogOpen, 
        onAddDialogClose,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onDeleteDialogClose,
        onEditDialogClose, 
        setTable,
        setSelectedRow
    } = useTable<ContractControl>('')
  
    const { data, isFetching, isPending, refetch } = useContractControlQuery()
    const {
        isOpen: isOpenUnsetttledSecurities,
        onClose: onCloseUnsetttledSecurities,
        onOpen: onOpenUnsetttledSecurities,
    } = useDisclosure()
    const {
        isOpen: isOpenUnsetttledSecuritiesInContractControl,
        onClose: onCloseUnsetttledSecuritiesInContractControl,
        onOpen: onOpenUnsetttledSecuritiesInContractControl,
    } = useDisclosure()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('SK_CONTROLE_CONTRATO', {
                id: 'SK_CONTROLE_CONTRATO',
                header: '#',
                size: 70,
            }),
            columnHelper.accessor('NM_CONTRATO', {
                id: 'NM_CONTRATO',
                header: 'Contrato',
                size: 350,
            }),
            columnHelper.accessor(({ SK_TEMPO_VENCIMENTO }) =>
                SK_TEMPO_VENCIMENTO
                    ? format(getDateFromId(Number(SK_TEMPO_VENCIMENTO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_TEMPO_VENCIMENTO',
                header: 'Data Vencimento',
                size: 150,
            }),
            columnHelper.accessor(({ SK_TEMPO_INCIO_NOTIFICACAO }) =>
                SK_TEMPO_INCIO_NOTIFICACAO
                    ? format(getDateFromId(Number(SK_TEMPO_INCIO_NOTIFICACAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_TEMPO_INCIO_NOTIFICACAO',
                header: 'Data Inicio Notificação',
                size: 180,
            }),
            columnHelper.accessor('DS_EMAILS_NOTIFICACAO', {
                id: 'DS_EMAILS_NOTIFICACAO',
                header: 'E-Mails Notificação',
                size: 300,
            }),
            columnHelper.accessor('DS_CONTRATO', {
                id: 'DS_CONTRATO',
                header: 'Descrição do Contrato',
                size: 400,
            }),
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => { 
                    return (
                        (
                            <TableRowActions
                                onClickDelete={() => onClickDelete(row)}
                                onClickEdit={() => onClickEdit(row)}
                                actions={
                                    [{
                                        label: 'Vincular Títulos', action: () => { 
                                            setSelectedRow(row) 
                                            onOpenUnsetttledSecurities()
                                        }, 
                                        icon: <Newspaper size={14}/>
                                    },
                                    {
                                        label: 'Títulos Vinculados', action: () => { 
                                            setSelectedRow(row) 
                                            onOpenUnsetttledSecuritiesInContractControl()
                                        }, 
                                        icon: <BookMarked size={14}/>
                                    }]
                                }
                            />
                        )
                    )
                },
                size: 60,
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ],
        []
    )
    console.log('aqui',selectedRow)
    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<ContractControlTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_CONTROLE_CONTRATO.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                getTableInstance={(table) => setTable(table)} 
                tableHeader={
                    <DadosMestreHeader
                        title={`Contratos`}
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions 
                        onRefech={refetch}
                        onAdd={onClickAdd}
                    />
                } 
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            {isDeleteDialogOpen && (
                <DeleteDialog
                    ids={
                        selectedRow
                            ? [selectedRow.original.SK_CONTROLE_CONTRATO]
                            : table
                            ? table
                                  .getFilteredRowModel()
                                  .flatRows.filter(
                                      (row) => selectedRows[row.id]
                                  )
                                  .map((row) => row.original.SK_CONTROLE_CONTRATO)
                            : []
                    }
                    isOpen={isDeleteDialogOpen}
                    onClose={onDeleteDialogClose}
                />
            )}
            {selectedRow && (
                <EditDialog
                    row={selectedRow}
                    isOpen={isEditDialogOpen}
                    onClose={onEditDialogClose}
                />
            )}
            <AddDialog isOpen={isAddDialogOpen} onClose={onAddDialogClose} />
            <UnsettledSecuritiesContractControlDialog 
                isOpen={isOpenUnsetttledSecurities} 
                onClose={onCloseUnsetttledSecurities} 
                ids={selectedRow ? selectedRow.original.SK_CONTROLE_CONTRATO : 0}
            />
            <UnsettledSecuritiesInContractControlDialog 
                isOpen={isOpenUnsetttledSecuritiesInContractControl} 
                onClose={onCloseUnsetttledSecuritiesInContractControl} 
                ids={selectedRow ? selectedRow.original.SK_CONTROLE_CONTRATO : 0}
            />
        </div>
    )
}

export default MasterDataContractControl
