import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useLimitBankQuery } from '@/queries/useLimitBankQuery'
import { LimitBankType } from '@/types/LimitBank'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography'
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Link } from 'react-router-dom' 
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { useTable } from '@/hooks/useTable'
import { useLimiteBancoSpreadsheets } from "./useLimitBankSpreadsheets"

export type LimitBankTable = TableData<LimitBankType>

const columnHelper = createColumnHelper<LimitBankType>()

const LimitBank = () => {
    const {
        table,
        tableState,  
        setTable,
    } = useTable<LimitBankType>('')

    const { onDownload } = useLimiteBancoSpreadsheets()

    const { data, isFetching, isPending, refetch } = useLimitBankQuery()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor('DD_EMPRESA', {
                id: 'DD_EMPRESA',
                header: 'Empresa(s)',
                size: 120,
            }),  
            columnHelper.accessor('DD_CODIGO', {
                id: 'DD_CODIGO',
                header: 'Cod. Banco',
                size: 120,
            }),  
            columnHelper.accessor('DD_VINCULO', {
                id: 'DD_VINCULO',
                header: 'Vínculo',
                size: 100,
            }),  
            columnHelper.accessor('DS_BANCO', {
                id: 'DS_BANCO',
                header: 'Banco',
                size: 260,
            }),  
            columnHelper.accessor(({ VL_TAXA }) =>
                VL_TAXA
                    ? percentageFormat(Number(VL_TAXA))
                    : percentageFormat(Number(0)), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TAXA',
                header: 'Valor Taxa',
                size: 110,
            }),  
            columnHelper.accessor(({ VL_LIMITE }) =>
                VL_LIMITE
                    ? currencyFormat(Number(VL_LIMITE))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_LIMITE',
                meta: {
                    header: {
                        className: '',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Limite <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Limite',
                size: 150,
            }),  
            columnHelper.accessor('DD_IOF', {
                id: 'DD_IOF',
                header: 'IOF',
                size: 80,
            }),  
            columnHelper.accessor('DD_TIPO_INSTITUICAO', {
                id: 'DD_TIPO_INSTITUICAO',
                header: 'Tipo Instituição',
                size: 120,
            }),  
            columnHelper.accessor('DD_TIPO_OPERACAO', {
                id: 'DD_TIPO_OPERACAO',
                header: 'Tipo Operação',
                size: 120,
            }),  
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS }) =>
                VL_TOTAL_COM_JUROS
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS',
                meta: {
                    header: {
                        className: '',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor Utilizado <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Valor Utilizado',
                size: 150,
            }),  
            columnHelper.accessor(({ SALDO }) =>
                SALDO
                    ? currencyFormat(Number(SALDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SALDO',
                meta: {
                    header: {
                        className: '',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Saldo <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Saldo',
                size: 150,
            }),  
            columnHelper.accessor(({ VL_VENCIDO }) =>
                VL_VENCIDO
                    ? currencyFormat(Number(VL_VENCIDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO',
                meta: {
                    header: {
                        className: '',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor Vencido <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Valor Vencido',
                size: 150,
            }),  
            columnHelper.accessor(({ VL_VENCIDO_HOJE }) =>
                VL_VENCIDO_HOJE
                    ? currencyFormat(Number(VL_VENCIDO_HOJE))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_HOJE',
                meta: {
                    header: {
                        className: '',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor Vencido Hoje <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
                header: 'Valor Vencido Hoje',
                size: 160,
            }),  
        ],
        []
    )

    return (
        <>
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Limite Banco
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <div className="w-full h-full p-4 overflow-hidden">
                    <Table<LimitBankTable>
                        data={memoData}
                        columns={memoColumns}
                        getRowId={(row) => row.SK_LIMITE_BANCO.toString()}
                        isLoading={isPending}
                        isFetching={isFetching}
                        getTableInstance={(table) => setTable(table)}  
                        tableState={tableState}  
                        tableHeader={(
                            <TypographyH4>Limite Banco</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-full gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)}
                                    variant="ghost"
                                >
                                <ArrowDownToLine size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />
                                
                            </div>
                        )}    
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                </div>
            </div>
        </>
    )
}

export default LimitBank
