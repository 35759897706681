import ExpiredToken from '@/components/ExpiredToken'
import Sidenav from '@/components/Sidenav'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { useBaseStore } from '@/store'
import { Outlet } from 'react-router-dom'
import { ReactNode, useEffect } from 'react'
import { cn } from '@/lib/utils'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { Store } from '@/store/type'
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { useToast } from '@/components/ui/use-toast'

interface MainLayoutProps {
    children?: ReactNode
}

const stateSelector = (state: Store) => ({
    isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
    tokenExpired: state.authSlice.state.tokenExpired,
    accessDenied: state.authSlice.state.accessDenied,
    resetAccessDenied: state.authSlice.actions.resetAccessDenied,
})

const MainLayout = ({ children }: MainLayoutProps) => {
    const { isSidenavOpen, tokenExpired, accessDenied, resetAccessDenied } = useBaseStore(stateSelector)

    const { toast } = useToast() 
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    useEffect(() => {
        if(accessDenied){
            toast({
                title: 'Acesso Negado',
                description: "Você não tem acesso a funcionalidade dessa página!",
                variant: 'destructive',
            })
 
            setTimeout(() => resetAccessDenied(), 10000);
        }
    },[accessDenied])

    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
        >
            <div className="w-full h-screen overflow-hidden bg-background">
                <Sidenav />
                <div
                    className={cn(
                        'h-full',
                        isSidenavOpen
                            ? 'ml-sidenav-closed w-container-sidenav-closed sm:ml-sidenav sm:w-container-sidenav'
                            : 'ml-sidenav-closed w-container-sidenav-closed'
                    )}
                >
                    <Header />
                    {tokenExpired && <ExpiredToken />}
                    <div
                        className={'h-container-header w-full overflow-y-auto'}
                    >
                        {children || <Outlet />}
                    </div>
                </div>
            </div>
            <ChangePasswordDialog />
        </DndContext>
    )
}

export default MainLayout
