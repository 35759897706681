import { fetchContractControl } from '@/api/business/contractcontrol'
import { useQuery } from '@tanstack/react-query'

export const ContractControlKeys = {
    all: ['contractcontrol'] as const,
    lists: () => [...ContractControlKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...ContractControlKeys.lists(), page, perPage, filters] as const,
    details: () => [...ContractControlKeys.all, 'detail'] as const,
    detail: (params: string) => [...ContractControlKeys.details(), params] as const,
}

export const useContractControlQuery = () => {
    return useQuery({
        queryFn: fetchContractControl,
        queryKey: ContractControlKeys.lists(),
    })
}
