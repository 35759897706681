import { fetchUnsettledSecuritiesPositionTotal } from '@/api/business/unsettledSecurities'
import { useQuery } from '@tanstack/react-query'
import { UnsettledSecuritiesPositionTotalProps } from '@/api/business/unsettledSecurities/type' 

export const UnsettledSecuritiesTotalPositionKeys = {
    all: ['producttotalposition'] as const, 
    details: () => [...UnsettledSecuritiesTotalPositionKeys.all, 'detail'] as const,
    detail: (data: UnsettledSecuritiesPositionTotalProps) => [...UnsettledSecuritiesTotalPositionKeys.details(), data] as const,
}

export const useUnsettledSecuritiesPositionTotal = (data: UnsettledSecuritiesPositionTotalProps, enableStatus: boolean) => {
    return useQuery({
        enabled: enableStatus,
        queryFn: () => fetchUnsettledSecuritiesPositionTotal(data),
        queryKey: UnsettledSecuritiesTotalPositionKeys.detail(data),
    })
}



