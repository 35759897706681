import { Newspaper , LucideIcon, Settings, Cog, Landmark, Home, Receipt, ChartBar} from 'lucide-react'

export const SIDENAV_ITEMS: SidenavItems[] = [
    {
        id: 1,
        label: 'Inicio',
        Icon: Home ,
        to: '/'
    },
    {
        id: 10,
        label: 'Dashboard',
        Icon: ChartBar,
        to: '/dashboard'
    },
    {
       
        id: 20,
        label: 'Títulos',
        Icon: Newspaper,
        subItems: [
            {
                id: 20.1,
                label: 'Posição 1',
                to: '/bonds/position1',
            }, 
            {
                id: 20.2,
                label: 'Posição 2',
                to: '/bonds/position2',
            }, 
            {
                id: 20.3,
                label: 'Posição 3',
                to: '/bonds/position3',
            }, 
            {
                id: 20.4,
                label: 'Posição Total',
                to: '/bonds/totalposition',
            },  
            {
                id: 20.5,
                label: 'Títulos Em Acordo', 
                to: '/bonds/bondsinagreement'
            },
        ],
    },
    {
        id: 30,
        label: 'Extrato Revenda',
        Icon: Receipt,
        subItems: [
            {
                id: 3.1,
                label: 'Em Aberto',
                to: '/bankstatement/opened'
            },
            {
                id: 3.2,
                label: 'Movimentações',
                to: '/bankstatement/movimentation'
            },
            {
                id: 3.3,
                label: 'Histórico de Títulos Baixados',
                to: '/bankstatement/historypayments'
            },
            {
                id: 3.4,
                label: 'Resumo do Saldo',
                to: '/bankstatement/balancesummary'
            }
        ]
    },
    {
        id: 40,
        label: 'Limite Banco',
        Icon: Landmark, 
        to: '/bank/limitbank'
            
    },
    {
        id: 50,
        label: 'Dados Mestre',
        Icon: Settings,
        subItems: [
            {
                id: 50.1,
                label: 'Cliente',
                to: '/masterdata/customer'
            },
            {
                id: 50.2,
                label: 'Revenda',
                to: '/masterdata/resale'
            },
            {
                id: 50.3,
                label: 'Produto',
                to: '/masterdata/product'
            },
            {
                id: 50.4,
                label: 'Contratos',
                to: '/masterdata/contractcontrol'
            }
        ]
    },
    
    {
        id: 60,
        label: 'Configurações',
        Icon: Cog,
        subItems: [
            {
                id: 60.1,
                label: 'Atualização de Base',
                to: '/settings/update'
            }, 
        ]
    }
]

export type SidenavItems = {
    id: number
    label: string
    to?: string
    Icon?: LucideIcon
    subItems?: SidenavItems[]
}
