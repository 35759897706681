import { useUnsettledSecuritiesInAgreementQuery } from "@/queries/useUnsettledSecuritiesInAgreementQuery"
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Link } from 'react-router-dom' 
import { useEffect, useMemo, useState } from 'react'
import Table from '@/components/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { TableData } from '@/components/Table/type'
import { UnsettledSecuritiesInAgreement } from "@/types/UnsettledSecuritiesInAgreement"
import DefaultColumn from '@/components/Table/components/DefaultColumn' 
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns' 
import { currencyFormat } from "@/utils/stringFormatter" 
import { TypographyH4 } from '@/components/ui/typography' 
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { FilterX, RefreshCcw } from "lucide-react"
// import { usePosition1Spreadsheets } from "./usePosition1Spreadsheets"
import Button from "@/components/Button" 
import { useTable } from "@/hooks/useTable" 

export type UnsettledSecuritiesInAgreementTable = TableData<UnsettledSecuritiesInAgreement>
 
const columnHelper = createColumnHelper<UnsettledSecuritiesInAgreement>()

type PositionProps = {
    position: string
    selected: boolean
}

const UnsettledSecuritiesInAgreementPage = () => {
    const {
        table 
    } = useTable<UnsettledSecuritiesInAgreement>('') 
    const [position, setPosition] = useState<PositionProps[]>([])
    const { data, isFetching, isPending, refetch } = useUnsettledSecuritiesInAgreementQuery()
    // const { onDownload } = usePosition1Spreadsheets()
 
    useEffect(()=> {
        if(data) {
            let distinctPosition = [...new Set(data.map(item => { 
                return item.DD_POSICAO
            }))]
             
            setPosition(distinctPosition.sort().map((item, index) => {
                return {position: item, selected: index == 0 ? true : false}
            }))
        }
    },[data])

    const handleSetPosition = (parameter) => { 
        setPosition(prev => {
            return prev.map(objeto => {  
                
                return {
                    ...objeto,
                    selected: objeto.position === parameter.position ? !objeto.selected : objeto.selected
                }
            });
        })
    }
    
    const memoData = useMemo(() => { 
        let itensFilter = [...new Set(position.map(item => { 
            if(item.selected)
            return item.position
        }))]
        let filteredData = data && data.filter(item => itensFilter.includes(item.DD_POSICAO))
 
        return filteredData || []
    } , [data, position])
  
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('DD_NUMERO_DOCUMENTO', {
                id: 'DD_NUMERO_DOCUMENTO',
                meta: {
                    row: {isGrouped: true}
                },
                header: 'Número Documento',
                size: 200 
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Empresa',
                size: 100,
            }), 
            columnHelper.accessor('DD_PARCELA', {
                id: 'DD_PARCELA',
                header: 'Parcela',
                size: 90,
            }), 
            columnHelper.accessor('DD_PORTADOR', {
                id: 'DD_PORTADOR',
                header: 'Portador',
                size: 100,
            }), 
            columnHelper.accessor('DD_AGENCIA', {
                id: 'DD_AGENCIA',
                header: 'Agência',
                size: 95,
            }), 
             columnHelper.accessor(({ VL_TITULO }) =>
                            VL_TITULO
                                ? currencyFormat(Number(VL_TITULO))
                                : '', {
                            cell: props => (
                                <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                            ),
                            sortingFn: (rowA, rowB, _columnId) => {
                                const statusA = rowA.original[_columnId]
                                const statusB = rowB.original[_columnId]
                                return Number(statusA) < Number(statusB) ? 1 : -1
                            },
                id: 'VL_TITULO',
                header: 'Valor',
                size: 140,
            }),  
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo',
                size: 75,
            }), 
            columnHelper.accessor('DD_NATUREZA_FINANCEIRA', {
                id: 'DD_NATUREZA_FINANCEIRA',
                header: 'Natureza Financeira',
                size: 240,
            }), 
            columnHelper.accessor(({ SK_EMISSAO }) =>
                SK_EMISSAO
                    ? format(getDateFromId(Number(SK_EMISSAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_EMISSAO',
                header: 'Data Emissão',
                size: 130,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO }) =>
                SK_VENCIMENTO
                    ? format(getDateFromId(Number(SK_VENCIMENTO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO',
                header: 'Data Vencimento',
                size: 150,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO_REAL }) =>
                SK_VENCIMENTO_REAL
                    ? format(getDateFromId(Number(SK_VENCIMENTO_REAL)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO_REAL',
                header: 'Data Venc. Real',
                size: 140,
            }), 
            columnHelper.accessor('DS_HISTORICO', {
                id: 'DS_HISTORICO',
                header: 'Histórico',
                size: 300,
            }), 
            columnHelper.accessor('DD_POSICAO', {
                id: 'DD_POSICAO',
                header: 'Posição',
                size: 140,
            }), 
           
              
        ],
        []
    )

    return (
        <>   
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Títulos Em Acordo
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>  
                <div className="flex-1 overflow-hidden">
                    <Table<UnsettledSecuritiesInAgreementTable>
                        data={memoData}
                        columns={memoColumns} 
                        isLoading={isPending}
                        isFetching={isFetching} 
                        expandAllRows={false}   
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-full gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                {/* <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)}
                                    variant="ghost"
                                >
                                    <ArrowDownToLine size={18} />
                                </ButtonWithTooltip> */}
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                    <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" /> 
                            </div>
                        )} 
                        tableHeader={(
                            <div className="flex gap-4 mt-4">
                                <TypographyH4 className="flex items-center text-md">Filtrar Posições</TypographyH4>
                                <div className="py-2 overflow-x-auto grid grid-cols-[repeat(7,minmax(150px,150px))] gap-4">  
                                    {position?.map((item) => (
                                        <Button 
                                            key={item.position}  
                                            size="sm"
                                            variant={item.selected ? "default" : "outline"}
                                            onClick={() => handleSetPosition(item)}
                                        > 
                                            {item.position}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        )}
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                </div>
            </div> 
        </>
    )
}

export default UnsettledSecuritiesInAgreementPage
