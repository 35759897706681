import { fetchProductTotalPosition } from '@/api/business/unsettledSecurities'
import { useQuery } from '@tanstack/react-query'

export const ProductTotalPositionKeys = {
    all: ['producttotalposition'] as const,
    lists: () => [...ProductTotalPositionKeys.all, 'list'] as const,
    list: (page: number, perPage: number, filters: any) =>
        [...ProductTotalPositionKeys.lists(), page, perPage, filters] as const,
    details: () => [...ProductTotalPositionKeys.all, 'detail'] as const,
    detail: (params: string) => [...ProductTotalPositionKeys.details(), params] as const,
}

export const useProductTotalPositionQuery = () => {
    return useQuery({
        queryFn: fetchProductTotalPosition,
        queryKey: ProductTotalPositionKeys.lists(),
    })
}



