import { fetchUnsettledSecuritiesInContractControl } from '@/api/business/contractcontrol'
import { useQuery } from '@tanstack/react-query'

export const UnsettledSecuritiesInContractControlKeys = {
    all: ['unsettledsecuritiesincontractcontrol'] as const, 
    details: () => [...UnsettledSecuritiesInContractControlKeys.all, 'detail'] as const,
    detail: (params: (number | string)) => [...UnsettledSecuritiesInContractControlKeys.details(), params] as const,
}

export const useUnsettledSecuritiesInContractControlQuery = (SK_CONTROLE_CONTRATO: (number | string)) => {
    return useQuery({
            queryFn: () => fetchUnsettledSecuritiesInContractControl(SK_CONTROLE_CONTRATO),
            queryKey: UnsettledSecuritiesInContractControlKeys.detail(SK_CONTROLE_CONTRATO),
    })
}
