import { Table } from '@tanstack/react-table'
import { UnsettledSecuritiesPositionTotaldTable } from '.'
import { utils, writeFile } from 'xlsx'
import { LimitBankType } from '@/types/LimitBank'
import { getDateFromId } from '@/utils/date' 
  
export const useUnsettledSecuritiesSpreadsheets = () => {
    const getTableRows = (table: Table<UnsettledSecuritiesPositionTotaldTable>): Partial<LimitBankType>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>
 
            obj['Distribuidor'] = row.original.NM_PROPRIETARIO
            obj['Revenda'] = row.original.NM_REVENDA
            obj['Empresa'] = row.original.ABREVIATURA_EMPRESA
            obj['Cod. Cliente'] = row.original.NK_CLIENTE
            obj['Nome Fantasia'] = row.original.DS_NOME_FANTASIA
            obj['UF'] = row.original.DD_ESTADO
            obj['Num. Doc'] = row.original.DD_NUMERO_DOCUMENTO
            obj['Parcela'] = row.original.DD_PARCELA
            obj['Valor a Vencer'] = Number(row.original.VL_A_VENCER)
            obj['Vl. Vencido'] = Number(row.original.VL_VENCIDO)
            obj['Vl. Vencido Hoje'] = Number(row.original.VL_VENCIDO_HOJE)
            obj['Dt. Emissão'] = getDateFromId(Number(row.original.SK_EMISSAO))
            obj['Dt. Vencimento'] = getDateFromId(Number(row.original.SK_VENCIMENTO))
            obj['Dt. Vencimento Real'] = getDateFromId(Number(row.original.SK_VENCIMENTO_REAL))
            obj['Nº Bordero'] = row.original.DD_NUMERO_BORDERO
            obj['Portador'] = row.original.DD_PORTADOR
            obj['Dt. Bordero'] = row.original.SK_TEMPO_BORDERO ?? getDateFromId(Number(row.original.SK_VENCIMENTO_REAL))
            obj['Tipo'] = row.original.DD_TIPO
            obj['Histórico'] = row.original.DS_HISTORICO
            obj['Cod. Produto'] = row.original.NK_PRODUTO
            obj['Produto'] = row.original.DS_PRODUTO
            obj['Tipo Produto'] = row.original.DD_TIPO_PRODUTO
            obj['Condição Pagamento'] = row.original.DD_NM_CONDICAO_PAGAMENTO
            obj['Posição'] = row.original.DD_POSICAO

            return obj
        })

      const onDownload = (tableInstance: Table<UnsettledSecuritiesPositionTotaldTable>) => {
             
            const rows = getTableRows(tableInstance)
            const colluns = Object.keys(rows[0]).length;
    
            const formatDate = 'dd/mm/yyyy'
            const formatMoneyAccounting = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'
    
            const worksheet = utils.aoa_to_sheet([[]]);
             
            worksheet['H1'] =  { t: 's', v: 'Totais: ' }
            worksheet['I1'] =  { t: 'n', f: `SUBTOTAL(9, I3:I${rows.length + 3})`, z: formatMoneyAccounting }
            worksheet['J1'] =  { t: 'n', f: `SUBTOTAL(9, J3:J${rows.length + 3})`, z: formatMoneyAccounting }
            worksheet['K1'] =  { t: 'n', f: `SUBTOTAL(9, K3:K${rows.length + 3})`, z: formatMoneyAccounting } 
            
            utils.sheet_add_json(worksheet, rows, {
                origin: { r: 1, c: 0 } 
            });
    
            for (let i = 2; i <= rows.length + 2; i++) { 
                
                worksheet[`L${i}`].z = formatDate
                worksheet[`M${i}`].z = formatDate
                worksheet[`N${i}`].z = formatDate 
                
                worksheet[`H${i}`].z = formatMoneyAccounting
                worksheet[`I${i}`].z = formatMoneyAccounting
                worksheet[`J${i}`].z = formatMoneyAccounting
                worksheet[`K${i}`].z = formatMoneyAccounting 
            }
    
            worksheet["!cols"] =  Array(colluns).fill({ wch: 20 });
    
            const workbook = utils.book_new()
    
            utils.book_append_sheet(workbook, worksheet, 'Titulos')
    
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear().toString();
            const currentDateFormated =  day + month + year;
    
            writeFile(workbook, `Titulos_Posicoes_${currentDateFormated}.xlsx`, {
                compression: true,
            })
        }

    return {
        onDownload,
    }
}
