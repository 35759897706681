import { ResponsiveBar } from '@nivo/bar'
import { TypographyLead } from "@/components/ui/typography"

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

type BarProps = {
    "Em Carteira": number
    "Em Acordo": number
    Tipo: string
}

type Props = {
    bar: BarProps[]
}

const Position3Chart = ({ bar } : Props) => {
  return (
    <div className="flex-1 flex flex-col rounded-xl bg-muted p-4">
            <TypographyLead className="content-center">Posição 3</TypographyLead>
            <div className=" flex-1">
           {bar && (
                <ResponsiveBar
                data={bar}
                keys={[
                    'Em Carteira',
                    'Em Acordo', 
                ]}
                indexBy="Tipo"
                margin={{ top: 20, right: 10, bottom: 70, left: 100 }}
                padding={0.15}
                valueFormat={value => `${currencyFormatter.format(value)} `}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'red_grey' }}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[
                    {
                        match: {
                            id: 'fries'
                        },
                        id: 'dots'
                    },
                    {
                        match: {
                            id: 'sandwich'
                        },
                        id: 'lines'
                    }
                ]}
                borderColor={{ theme: 'background' }}
                axisTop={null}
                axisRight={null}  
                axisLeft={{
                    format: (value) => `${currencyFormatter.format(value)} `,
                    tickValues: 5
                }}
                enableLabel={false}
                labelSkipHeight={7}
                labelTextColor="black"
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: -50,
                        translateY: 60,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
            />

           )}
           </div>
        </div>
  )
}

export default Position3Chart
