import { UnsettledSecuritiesPositionTotal } from '@/api/business/unsettledSecurities/type'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import {
    Dialog,
    DialogContent
} from '@/components/ui/dialog' 
import { useMemo } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX  } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography' 
import { useTable } from '@/hooks/useTable'
import { createColumnHelper } from '@tanstack/react-table'
import { currencyFormat } from '@/utils/stringFormatter'
import { format } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import { useUnsettledSecuritiesSpreadsheets } from "./useUnsettledSecuritiesSpreadsheets"

export type UnsettledSecuritiesPositionTotaldTable = TableData<UnsettledSecuritiesPositionTotal>

const columnHelper = createColumnHelper<UnsettledSecuritiesPositionTotal>()

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
    data: UnsettledSecuritiesPositionTotal[],
    isFetching: boolean
    isLoading: boolean
}

const DialogUnsettledSecurities = ({ isOpen, onClose, data,  isFetching, isLoading}: AddDialogProps) => {
    const {
        table,  
        setTable,
    } = useTable<UnsettledSecuritiesPositionTotal>('') 
        
    const { onDownload } = useUnsettledSecuritiesSpreadsheets()

    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor('NM_PROPRIETARIO', {
                id: 'NM_PROPRIETARIO',
                header: 'Distribuidor',
                size: 160,
            }),  
            columnHelper.accessor('NM_REVENDA', {
                id: 'NM_REVENDA',
                header: 'Revenda',
                size: 160,
            }),  
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 120,
            }),  
            columnHelper.accessor('NK_CLIENTE', {
                id: 'NK_CLIENTE',
                header: 'Cod. Cliente',
                size: 115,
            }),  
            columnHelper.accessor('DS_NOME_FANTASIA', {
                id: 'DS_NOME_FANTASIA',
                header: 'Nome Fantasia',
                size: 200,
            }),  
            columnHelper.accessor('DD_ESTADO', {
                id: 'DD_ESTADO',
                header: 'UF',
                size: 75,
            }),  
            columnHelper.accessor('DD_NUMERO_DOCUMENTO', {
                id: 'DD_NUMERO_DOCUMENTO',
                header: 'Num. Doc',
                size: 120,
            }),  
            columnHelper.accessor('DD_PARCELA', {
                id: 'DD_PARCELA',
                header: 'Parcela',
                size: 100,
            }),  
            columnHelper.accessor(({ VL_A_VENCER }) =>
                VL_A_VENCER
                    ? currencyFormat(Number(VL_A_VENCER))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER',
                header: 'Valor a Vencer',
                size: 150,
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor a Vencer <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
            }),   
            columnHelper.accessor(({ VL_VENCIDO }) =>
                VL_VENCIDO
                    ? currencyFormat(Number(VL_VENCIDO))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO',
                header: 'Vl. Vencido',
                size: 150,
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor Vencido <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
            }),  
            columnHelper.accessor(({ VL_VENCIDO_HOJE }) =>
                VL_VENCIDO_HOJE
                    ? currencyFormat(Number(VL_VENCIDO_HOJE))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_HOJE',
                header: 'Vl. Vencido Hoje',
                size: 160,
                meta: {
                    header: {
                        className: 'bg-green-50',
                        menu: {
                            renderDescriptionMenu: ({header,table}) => {

                                let data = table.getFilteredRowModel().flatRows.map(item => item.original)
                                const amount = data.reduce((accumulator, currentValue) => {
                                    return accumulator + Number(currentValue[header.column.columnDef.id]);
                                }, 0); 
 
                                return <>Valor Vencido Hoje <p className="block">{currencyFormat(amount)}</p></>
                            }
                        }
                    }
                },
            }),  
            columnHelper.accessor(({ SK_EMISSAO }) =>
                SK_EMISSAO
                    ? format(getDateFromId(Number(SK_EMISSAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_EMISSAO',
                header: 'Dt. Emissão',
                size: 120,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO }) =>
                SK_VENCIMENTO
                    ? format(getDateFromId(Number(SK_VENCIMENTO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO',
                header: 'Dt. Vencimento',
                size: 135,
            }),     
            columnHelper.accessor(({ SK_VENCIMENTO_REAL }) =>
                SK_VENCIMENTO_REAL
                    ? format(getDateFromId(Number(SK_VENCIMENTO_REAL)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO_REAL',
                header: 'Dt. Vencimento Real',
                size: 160,
            }),   
            columnHelper.accessor('DD_NUMERO_BORDERO', {
                id: 'DD_NUMERO_BORDERO',
                header: 'Nº Bordero',
                size: 120,
            }),  
            columnHelper.accessor('DD_PORTADOR', {
                id: 'DD_PORTADOR',
                header: 'Portador',
                size: 100,
            }),  
            columnHelper.accessor(({ SK_TEMPO_BORDERO }) =>
                SK_TEMPO_BORDERO
                    ? format(getDateFromId(Number(SK_TEMPO_BORDERO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_TEMPO_BORDERO',
                header: 'Dt. Bordero',
                size: 120,
            }),   
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo',
                size: 80,
            }),  
            columnHelper.accessor('DS_HISTORICO', {
                id: 'DS_HISTORICO',
                header: 'Histórico',
                size: 140,
            }),  
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod. Produto',
                size: 130,
            }),  
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 200,
            }),  
            columnHelper.accessor('DD_TIPO_PRODUTO', {
                id: 'DD_TIPO_PRODUTO',
                header: 'Tipo Produto',
                size: 130,
            }),  
            columnHelper.accessor('DD_NM_CONDICAO_PAGAMENTO', {
                id: 'DD_NM_CONDICAO_PAGAMENTO',
                header: 'Condição Pagamento',
                size: 170,
            }),  
            columnHelper.accessor('DD_POSICAO', {
                id: 'DD_POSICAO',
                header: 'Posição',
                size: 120,
            }),  
                
        ],
        []
        )
          
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[80%] h-3/4'> 
                <Table<UnsettledSecuritiesPositionTotaldTable>
                        data={memoData}
                        columns={memoColumns}
                        isLoading={isLoading}
                        isFetching={isFetching} 
                        expandAllRows={true}   
                        tableHeader={(
                            <TypographyH4>Títulos</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-[220px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button> 
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)} 
                                    variant="ghost"
                                >
                                <ArrowDownToLine size={18} />
                                </ButtonWithTooltip> 
                                
                            </div>
                        )}     
                        getTableInstance={(table) => { 
                            setTable(table) 
                        }} 
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    />
            </DialogContent>
        </Dialog>
    )
}
    
export default DialogUnsettledSecurities
