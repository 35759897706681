import instance from '@/api/business' 
import { 
    ContractControl, 
    DeleteContractControlProps, 
    CreatedContractControlProps, 
    UpdateContractControlProps, 
    UnsettledSecuritiesContractControl, 
    CreatedUnsettledSecuritiesInContractControlProps,
    DeleteUnsettledSecuritiesInContractControlProps
} from './type'

const BASE_URL = '/contractcontrol/crweb/contractcontrol'

export const fetchContractControl = async () => {
    const response = await instance.post<ContractControl[]>(BASE_URL)

    return response.data
}

export const createContractControl = async (data: CreatedContractControlProps) => {
    const response = await instance.put(BASE_URL, data)

    return response.data

}

export const updateContractControl = async (data: UpdateContractControlProps) => {
    const response = await instance.patch(BASE_URL, data)

    return response.data
}

export const deleteContractControl = async (props: DeleteContractControlProps) => {
    const response = await instance.delete(BASE_URL, {
        data: props,
    })

    return response.data
} 

export const fetchUnsettledSecuritiesContractControl = async (SK_CONTROLE_CONTRATO: (number | string)) => {
    const response = await instance.post<UnsettledSecuritiesContractControl[]>('/contractcontrol/crweb/unsettledsecuritiescontractcontrol', {
        SK_CONTROLE_CONTRATO
    })

    return response.data
}

export const fetchUnsettledSecuritiesInContractControl = async (SK_CONTROLE_CONTRATO: (number | string)) => {
    const response = await instance.post<UnsettledSecuritiesContractControl[]>('/contractcontrol/crweb/unsettledsecuritiesincontractcontrol',{
        SK_CONTROLE_CONTRATO
    })

    return response.data
}

export const createUnsettledSecuritiesInContractControl = async (data: CreatedUnsettledSecuritiesInContractControlProps) => {
    const response = await instance.put('/contractcontrol/crweb/unsettledsecuritiesincontractcontrol', data)

    return response.data
}

export const deleteUnsettledSecuritiesInContractControl = async (data: DeleteUnsettledSecuritiesInContractControlProps) => {
    const response = await instance.delete('/contractcontrol/crweb/unsettledsecuritiesincontractcontrol', {
        data: data
    })

    return response.data
}